<template>
  <div>
    <div>
      <season-selector
        v-model="selectedSeason"
        :options="[2023, 2022, 2021]"
        @change="refresh()"
      />
    </div>
    <div>
      <b-form-select
        v-model="selectedCompetitionType"
        :options="competitionTypesForSelect"
        @change="refresh()"
      />
    </div>
    <div>
      <b-form-select
        v-model="selectedLevel"
        :options="levelsForSelect"
        @change="refresh()"
      />
    </div>
    <div>
      <b-form-select
        v-model="selectedRule"
        :options="rulesForSelect"
        @change="refresh()"
      />
    </div>
    <b-button
      :disabled="!changed"
      @click="saveChanges"
    >Enregistrer les modifications</b-button>
  </div>
</template>

<script>
import seasonSelector from '@/components/SeasonSelector.vue';
import { REF_COMPETITION_TYPE, REF_LEVEL, REF_CATEGORY, REF_COMPULSORY, SEASON_COMPETITION_TYPE_CATEGORY_LEVEL } from "@/LocalDB";

export default{
  components:{ seasonSelector },
  data(){
    return {
      selectedSeason : null,
      selectedCompetitionType: null,
      selectedLevel: null,
      selectedRule: null,
      selectionRead: '',
      changed: false,
    }
  },
  computed:{
    competitionTypesForSelect(){
      var ret = SEASON_COMPETITION_TYPE_CATEGORY_LEVEL.query()
              .where(c => c.year == this.selectedSeason)
              .get()
              .map(c => c.competition_type)
              .filter((value, index, self) => { return self.indexOf(value) === index; })
              .map(ct => { return REF_COMPETITION_TYPE.query().where("code", ct).first(); })
              .map(ct => { return { value: ct.code, text: ct.name }});
      return ret;
    },
    levelsForSelect()
    {
      if(this.selectedCompetitionType == null)
        return [];

      var ret = SEASON_COMPETITION_TYPE_CATEGORY_LEVEL.query()
              .where(c => !c.isArchived)
              .where(c => c.competition_type == this.selectedCompetitionType)
              .get()
              .map(c => c.level)
              .filter((value, index, self) => { return self.indexOf(value) === index; })
              .map(l => { return REF_LEVEL.query().where('code', l).first(); })
              .map(l => { return { value: l.code, text: l.name }});
      console.log(ret);
      return ret;
    },
    categoriesForSelect()
    {
      return REF_COMPULSORY.query()
              .where(c => !c.isArchived)
              .where(c => c.competition_type == this.selectedCompetitionType)
              .where(c => c.level == this.selectedLevel)
              .get()
              .map(c => c.category)
              .filter((value, index, self) => { return self.indexOf(value) === index; })
              .map(c => { return REF_CATEGORY.query().where('code', c).first(); })
              .map(c => { return { value: c.code, text: c.name }});
    },
    compulsories(){
      return REF_COMPULSORY.query()
              .where(c => !c.isArchived)
              .where(c => c.competition_type == this.selectedCompetitionType)
              .where(c => c.level == this.selectedLevel)
              .where(c => c.category == this.selectedCategory)
              .get()
              .sort((a,b) => (a.code < b.code) ? -1 : ((a.code > b.code) ? 1 : 0))
              .map(c => { return { value: c.id, text: c.code + ' - ' + c.name }; })
    },
  },
}
</script>
